import React, { useState, useCallback, useEffect } from "react";
import { Divider, message, Card, Row, Col } from "antd";
import service from "services/apiServices";
import moment from 'moment';
import { Spinner, Info, Flag, EditHistory } from "common";
import labels from "assets/labels/participant.json";
import Restrictions from "common/restrictions";

const styles = {
  cardItem: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  listItem: {
    border: '1px solid black',
    marginBottom: '12px',
    maxHeight: 250,
    overflow: 'hidden'
  },
  divider: {
    margin: '6px 0'
  }
}

const OralHistoryView = ({ id, data }) => {

  const [loading, setLoading] = useState(true);
  const [oral, setOral] = useState({});
  const [participant, setParticipant] = useState({});

  const getParticipant = useCallback(async (participantId) => {
    try {
      const result = await service.read("participants", participantId);
      setParticipant({ ...result });
      setOral(result.oralHistory);
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experiencing some network related issues. Please try again in a minute.",
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getParticipant(id);
  }, [id, getParticipant]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider className='divider' />
    </div>
  )

  if (loading) return <Spinner />;
  return (
    <>
      <EditHistory lastUpdate={oral.lastUpdate} editHistory={oral.editHistory} />
      {!!oral.restrictions && <Restrictions />}
      {oral.id !== 0 && (
        <Card style={styles.cardItem}>
          {cardTitle('Oral History', oral.mediaType, '')}
          <Row>
            <Col span={6}><Info label={labels.INTERVIEWER} value={oral.interviewer} /></Col>
            <Col span={6}><Info label={labels.LOCATION} value={oral.location} /></Col>
            <Col span={6}><Info label={labels.LENGTH} value={oral.length} /></Col>
            <Col span={6}><Info label={labels.SENT} value={oral.sent.toString()} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Flag label={labels.TRANSCRIBER} value={oral.transcriber} flag={oral.transcribed} note={labels.NOT_TRANSCRIBED} /></Col>
            <Col span={6}><Flag label={labels.TRANSLATOR} value={oral.translator} flag={oral.translated} note={labels.NOT_TRANSLATED} /></Col>
            <Col span={6}><Flag label={labels.EDITOR_BHS} value={oral.editorBHS} flag={oral.editedBHS} note={labels.NOT_EDITED} /></Col>
            <Col span={6}><Flag label={labels.EDITOR_ENG} value={oral.editorEng} flag={oral.editedEng} note={labels.NOT_EDITED} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Info label={labels.SUBTITLES} value={oral.subtitles.toString()} /></Col>
            <Col span={6}><Info label={labels.EDITED_VIDEO} value={oral.editedVideo.toString()} /></Col>
            <Col span={6}><Info label={labels.PAGES} value={oral.pages} /></Col>
            <Col span={6}><Info label={labels.NATIVE_LANGUAGE} value={oral.nativeLanguage} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Info label={labels.TRANSCRIPT_URL} value={oral.transcriptUrl} /></Col>
            <Col span={6}><Info label={labels.MEDIA_URL} value={oral.mediaUrl} /></Col>
            <Col span={6}><Info label={labels.INTER_DATE} value={moment(oral.interDate).format("DD.MMM.YYYY")} /></Col>
            <Col span={6}><Info label={labels.INTER_NOTES} value={oral.interNotes} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Info label={labels.TRANS_STORAGE} value={oral.storageTran} />
          <Divider style={styles.divider} />
          <Info label={labels.TEST_STORAGE} value={oral.storageTest} />
          <Divider style={styles.divider} />
          <Info label={labels.NOTES} value={oral.notes} />
        </Card>
      )}

      <Card style={styles.cardItem}>
        {cardTitle(participant.fullName, 'Id: ' + participant.id)}
        {/* <EditHistory lastUpdate={participant.lastUpdate} editHistory={participant.editHistory} /> */}
        <Row>
          <Col span={8}><Info label={labels.GENDER} value={participant.gender} /></Col>
          <Col span={8}><Info label={labels.YEAR_OF_BIRTH} value={participant.year} /></Col>
          <Col span={8}><Info label={labels.BIRTH_PLACE} value={participant.birthPlace} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={8}><Info label={labels.CONFLICT} value={participant.conflict} /></Col>
          <Col span={8}><Info label={labels.PHONE} value={participant.phone} /></Col>
          <Col span={8}><Info label={labels.EMAIL} value={participant.email} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={8}><Info label={labels.PARTICIPATION_DATE} value={moment(participant.participationDate).format("DD/MM/YYYY")} /></Col>
          <Col span={8}><Info label={labels.RESEARCH_CELL} value={participant.researchCell} /></Col>
          <Col span={8}><Info label={labels.INSTITUTION} value={participant.institution} /></Col>
        </Row>
      </Card>

    </>
  );
};

export default OralHistoryView;
