import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Select, Checkbox, Space, Table, Modal } from "antd";
import { PlayCircleTwoTone } from '@ant-design/icons';
import service from 'services/apiServices';
import config from 'config';
import ViewTrans from './components/viewTrans';
import { Curtain } from 'common';

const styles = {
  select: {
    textAlign: 'left',
    fontSize: '11px',
    borderRadius: '4px',
    border: 'solid 1px black',
    backgroundColor: 'white',
    color: 'black',
    width: '90%'
  },
  col: {
    padding: '0 8px'
  }
};

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };

const columns = [
  { key: 'storyId', title: 'Story ID', ellipsis: true, dataIndex: 'storyId', width: '8%', render: x => service.toFormat(x) },
  { key: 'language', title: 'Language', ellipsis: true, dataIndex: 'language', width: '8%' },
  { key: 'status', title: 'Status', ellipsis: true, dataIndex: 'status', width: '10%' },
  { key: 'name', title: 'Name', ellipsis: true, dataIndex: 'name', width: '10%' },
  { key: 'age', title: 'YOB', ellipsis: true, dataIndex: 'age', width: '8%', align: 'center' },
  { key: 'audio', title: 'Audio', ellipsis: true, dataIndex: 'audio', width: '6%', align: 'center', render: x => x !== 'n/a' ? <PlayCircleTwoTone /> : "" },
  { key: 'title', title: 'Title', ellipsis: true, dataIndex: 'title', width: '25%' },
  { key: 'text', title: 'Text', ellipsis: true, dataIndex: 'text', width: '25%' }
]

const AudioFilter = ({ data }) => {

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');
  const [tags, setTags] = useState([]);
  const [trans, setTrans] = useState([]);
  const [view, setView] = useState(false);
  const [item, setItem] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getTags = useCallback(async () => {
    try {
      setLoading(true);
      setTags(await service.list("master/tags/5"));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const statusOptions = [
    { label: 'Created', value: 'Created' },
    { label: 'Proofread', value: 'Proofread' },
    { label: 'Finalized', value: 'Finalized' }
  ]

  const audioOptions = [
    { label: 'YES', value: 'YES' },
    { label: 'NO', value: 'NO' }
  ]

  const onFinish = async values => {
    const request = {
      statuses: values.status ? values.status.join(',') : '',
      languages: values.languages ? values.languages.join(',') : '',
      audio: values.audio ? values.audio.join(',') : '',
      ids: data
    }
    setLoading(true);
    const response = await service.insert("storytranslations/search", request);
    setLoading(false);
    setLink('');
    setTrans(response);
  }
  const onFailed = error => console.error(error);

  const pdfExport = async () => {
    const request = {
      ide: selectedRowKeys
    }
    setLoading(true);
    const response = await service.insert("storytranslations/pdf", request);
    // const response = await service.insert("pdf2/test", request);
    setLoading(false);
    if (response) setLink(config.documentPath + response);
  }

  const TableTitle = () =>
    <div>
      <div className='left'>
        <h2 align='left'>Stories {trans.length}</h2>
      </div>
      <div className='right'>
        <Button type='primary' htmlType="button"
          className='tableButton'
          onClick={pdfExport}
          style={{ margin: 0, height: 36, width: 164 }}>
          EXPORT STORIES
        </Button>
        {!!link && <>
          <Button type="default" style={{ marginLeft: 24 }}>
            <a href={link} target="_blank" rel="noopener noreferrer" download>VIEW STORIES</a>
          </Button>
        </>}
      </div>
    </div>

  const viewTrans = record => {
    setItem(record);
    setModalTitle(record.title);
    setView(true);
  }

  const closeTrans = () => {
    setView(false);
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return (<>
    {loading && <Curtain />}
    <Form form={form} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
      <Form.Item label='Story status' name="status">
        <Checkbox.Group>
          <Space direction='horizontal'>
            {statusOptions.map(o => <div key={o.value}><Checkbox value={o.value}>{o.label}</Checkbox></div>)}
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item label='Audio recorded' name="audio">
        <Checkbox.Group>
          <Space direction='horizontal'>
            {audioOptions.map(o => <div key={o.value}><Checkbox value={o.value}>{o.label}</Checkbox></div>)}
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item label='Language(s)' name="languages">
        <Select mode='multiple' optionFilterProp="children" style={styles.select}
          filterOption={(input, option) => service.anything(input, option.children)}>
          {tags.map(c => (<Select.Option key={c.id} value={c.name}>{c.name}</Select.Option>))}
        </Select>
      </Form.Item>
      <hr />
      <Button type='primary' htmlType='submit'>SUBMIT</Button>
    </Form>

    {!!trans.length &&
      <Table
        size="small"
        title={TableTitle}
        tableLayout={"fixed"}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={trans}
        pagination={false}
        onRow={(record, index) => ({ onClick: () => { viewTrans(record); } })}
        rowKey={row => row.id}
      />
    }

    <Modal width={'60%'}
      style={{ top: '20px' }}
      open={view} title={<h2>{modalTitle}</h2>}
      footer={[]}
      onCancel={closeTrans}
      destroyOnClose={true}>
      <ViewTrans item={item} onOk={closeTrans} />
    </Modal>

  </>
  );
}

export default AudioFilter;