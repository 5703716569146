
const Buttons = {
    button: {
        width: '120px',
        height: "32px",
        margin: "8px",
        borderRadius: "6px",
        fontWeight: "500",
        border: "1px solid #bcab98",
        backgroundColor: "#DDC7B0",
        color: "black"
    },
    cancel: {
        width: '120px',
        height: "32px",
        margin: "8px",
        borderRadius: "6px",
        fontWeight: "500",
        color: "black",
        backgroundColor: "white",
        border: "1px solid black"
    },
    close: {
        width: '120px',
        height: "32px",
        margin: "8px",
        borderRadius: "6px",
        fontWeight: "500",
        color: "black",
        backgroundColor: "white",
        border: "1px solid black"
    },
    confirm: {
        width: '120px',
        height: "32px",
        margin: "8px",
        borderRadius: "6px",
        fontWeight: "500",
        border: "none",
        color: "white",
        backgroundColor: "black"
    },
    delete: {
        width: '96px',
        height: "32px",
        margin: "0 8px",
        borderRadius: "4px",
        fontWeight: "400",
        fontSize: '0.9em',
        border: "none",
        backgroundColor: "#900",
        color: "white"
    },
    save: {
        width: '160px'
    }
}

export default Buttons;