import React, { useEffect, useState } from 'react';
import { message, Modal, Layout } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import services from 'services/apiServices';
import { Curtain } from 'common';
import Password from './Password';

const SetPassword = () => {
  const history = useHistory();
  const token = useParams().t;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function check(token) {
      setLoading(true);
      const result = await services.list(`password/${token}`);
      setLoading(false);
      if (!result) return history.push('/login');
      setUser(result);
      setVisible(true);
    };
    check(token);
    // eslint-disable-next-line
  }, [token])

  const submitPass = async values => {
    setLoading(true);
    const result = await services.insert(`password/${token}`, values);
    setLoading(false);
    if (result) {
      message.success('Password changed successfully. You can now login to the application');
      return history.push('/login');
    } else {
      message.error('An error occured during password change. Please try again later.');
    }
  }

  const closePass = () => {
    setVisible(false);
  }

  return (
    <div>
      {loading && <Curtain />}
      <Layout className="bgReset">
        <Modal title={<h2>SET YOUR PASSWORD</h2>} open={visible} onCancel={closePass} centered width="50%" footer={[]}>
          <Password name={user.name} onClose={closePass} onSubmit={submitPass} />
        </Modal>
      </Layout>
    </div>
  )
};

export default SetPassword;