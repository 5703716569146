import React, { useEffect } from 'react';

import config from 'config';
import { clearToken } from 'services/authServices'

export default function Logout({ history }) {

  useEffect(() => {
    const eraseToken = () => {
      try {
        config.currentUser = { id: 0, name: '', role: '', landing: '', conflict: 0, canDelete: false };
        config.token = '';
        clearToken();
        history.push('/home');
      } catch (error) {
        console.error(error);
      }
    }
    eraseToken();
  }, [history]);

  return <div />
}
