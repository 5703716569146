import { configureStore } from "@reduxjs/toolkit";
import respondentReducer from './respondentSlice';
import pageReducer from './pageSlice';

const reducer = {
  respondent: respondentReducer,
  page: pageReducer
};

const store = configureStore({
  reducer: reducer,
  devTools: false
})

export default store;

// const store = configureStore({
//  reducer: {
//    //your reducers
//  },
//  devTools: process.env.NODE_ENV !== 'production',
// });