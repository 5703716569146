import React, { useState, useEffect } from "react";
import { Table, Layout } from "antd";

import TableHead from "services/TableHead";
import service from "services/apiServices";
import { isSuper } from "services/authServices";

const Conflicts = ({ history }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    const result = await service.list('conflicts');
    if (result) setData(result);
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  const TableTitle = () => <h2>Armed Conflicts</h2>;

  const colProps = [
    // { key: "id", title: "Id", width: '10%' },
    { key: "country", title: "Armed Conflict", width: '55%' },
    { key: "beginYear", title: "Start", width: '12%', align: 'center' },
    { key: "endYear", title: "End", width: '12%', align: 'center' },
  ];

  const columns = () =>
    TableHead(
      colProps,
      isSuper(),
      'conflicts',
      true
    );

  return (
    <Layout className="container">
      <Table style={{ width: '75%', margin: '0 auto' }}
        loading={isLoading}
        title={TableTitle}
        size="small"
        columns={columns()}
        dataSource={data}
        pagination={false}
        ellipsis={false}
        rowKey={(row) => row.id}
        //rowKey="index"
      />
    </Layout>
  );
};

export default Conflicts;
