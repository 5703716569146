import React, { useState, useCallback, useEffect } from "react";
import { Drawer, Divider, message, List, Card, Row, Col } from "antd";
import service from "services/apiServices";
import moment from 'moment';
import { StoryView } from "components/stories";
import { ObjectView } from "components/objects";
import { EditHistory, Spinner, Info, Flag } from "common";
import labels from "assets/labels/participant.json";
import ResidencesView from "./residencesView";
import Restrictions from "common/restrictions";
import { Table } from "antd";
import TableView from "services/TableView";

const styles = {
  cardItem: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  listItem: {
    border: '1px solid black',
    marginBottom: '12px',
    maxHeight: 250,
    overflow: 'hidden'
  },
  divider: {
    margin: '6px 0'
  }
}

const ParticipantView = ({ id }) => {

  const [loading, setLoading] = useState(true);
  const [oral, setOral] = useState({});
  const [participant, setParticipant] = useState({});
  // eslint-disable-next-line
  const [statement, setStatement] = useState({});
  const [showStoryDetailView, setShowStoryDetailView] = useState(false);
  const [selectedStoryId, setSelectedStoryId] = useState(0);
  const [showObjectDetailView, setShowObjectDetailView] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState({});

  const getParticipant = useCallback(async (participantId) => {
    try {
      setLoading(true);
      const result = await service.read("participants", participantId);
      const stateRec = await service.list(`participants/${participantId}/statement`);
      setStatement(stateRec);
      setParticipant({ ...result });
      setOral(result.oralHistory);
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experiencing some network related issues. Please try again in a minute.",
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getParticipant(id);
  }, [id, getParticipant]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider className='divider' />
    </div>
  )

  // const smallFont = history => <span style={{ fontSize: '0.7em' }}>{history}</span>;

  const storyColumns = () => {
    const colProps = [
      { key: 'id', dataIndex: 'id', title: "ID", width: '10%', render: x => service.toFormat(x) },
      { key: 'title', title: 'Title', width: '40%' },
      { key: 'text', title: 'Text', width: '50%', render: x => x.replace('<p>', '').replace('</p>', '') }
    ];
    return TableView(colProps);
  }
  const StoriesTitle = () => <div style={{ float: 'left' }}><h2 align='left'>Stories / {participant.stories.length}</h2></div>;

  const pieceColumns = () => {
    const colProps = [
      { key: 'objectId', dataIndex: 'objectId', title: "ID", width: '10%'},
      { key: 'name', title: 'Name', width: '40%' },
      { key: 'description', title: 'Description', width: '50%', render: x => x.replace('<p>', '').replace('</p>', '') }
    ];
    return TableView(colProps);
  }
  const PiecesTitle = () => <div style={{ float: 'left' }}><h2 align='left'>Objects / {participant.pieces.length}</h2></div>;

  const MediaItem = ({ item }) => {
    return (
      <List.Item>
        <Card bodyStyle={{ cursor: "pointer" }} style={styles.listItem}
          onClick={() => window.open(item.url, "_blank")}
          width={'50%'}>
          {item.comment}
        </Card>
      </List.Item>
    );
  };

  if (loading) return <Spinner />;
  return (
    <>
      {!!participant.restrictions && <Restrictions />}
      <Card style={styles.cardItem}>
        {cardTitle(participant.fullName, 'Id: ' + participant.id)}
        <EditHistory lastUpdate={participant.lastUpdate} editHistory={participant.editHistory} />
        <Row>
          <Col span={8}><Info label={labels.GENDER} value={participant.gender} /></Col>
          <Col span={8}><Info label={labels.YEAR_OF_BIRTH} value={participant.year} /></Col>
          <Col span={8}><Info label={labels.BIRTH_PLACE} value={participant.birthPlace} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={8}><Info label={labels.PHONE} value={participant.phone} /></Col>
          <Col span={8}><Info label={labels.EMAIL} value={participant.email} /></Col>
          <Col span={8}><Info label={labels.RESIDENCE} value={participant.currentResidence} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={8}><Info label={labels.CONFLICT} value={participant.conflict} /></Col>
          <Col span={8}><Info label={labels.INSTITUTION} value={participant.institution} /></Col>
          {participant.legalGuardian && (<Col span={8}><Info label={labels.LEGAL_GUARDIAN} value={participant.legalGuardian} /></Col>)}
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={8}><Info label={labels.RESEARCH_CELL} value={participant.researchCell} /></Col>
          <Col span={8}><Info label={labels.STATEMENT_ID} value={participant.statementId} /></Col>
          <Col span={8}><Info label={labels.PARTICIPATION_DATE} value={moment(participant.participationDate).format("DD/MM/YYYY")} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Info label={labels.NOTES} value={<pre>{participant.notes}</pre>} />
        </Row>

      </Card>

      <ResidencesView
        returnCountry={participant.returnHomeCountry}
        returnTown={participant.returnHomeTown}
        residences={participant.residences}
        refugee={participant.refugee}
        returnee={participant.returnee}
        idp={participant.idp}
        stateless={participant.stateless}
        unChild={participant.unChild}
        homeTown={participant.homeTownCountry} />

      {participant.stories.length !== 0 && (
        <Table
          title={StoriesTitle}
          size="small"
          tableLayout={"fixed"}
          columns={storyColumns()}
          dataSource={participant.stories}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showQuickJumper: true
          }}
          rowKey={(row) => row.id}
          onRow={(record, index) => ({ onClick: () => { setShowStoryDetailView(true); setSelectedStoryId(record.id); } })}
        />
      )}

      {participant.pieces.length !== 0 && (
        <Table
          title={PiecesTitle}
          size="small"
          tableLayout={"fixed"}
          columns={pieceColumns()}
          dataSource={participant.pieces}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showQuickJumper: true
          }}
          rowKey={(row) => row.id}
          onRow={(record, index) => ({ onClick: () => { setShowObjectDetailView(true); setSelectedObjectId(record.id); } })}
        />
      )}

      {participant.mediaAttachments.length !== 0 && (
        <><Divider />
        <Card style={styles.cardItem}>
          {cardTitle('Media Attachments', '', '')}
          <List
            size="small"
            grid={{ gutter: 16, column: 4 }}
            dataSource={participant.mediaAttachments}
            renderItem={(item) => <MediaItem item={item} />}
          />
        </Card>
        </>
      )}

      {/* {!!statement.id && statement.id !== 0 && (
        <><Divider />
        <Card style={styles.cardItem}>
          {cardTitle('Statement', smallFont(statement.editHistory), '')}
          <div>{statement.comment}</div>
          <iframe title='Statement' style={{ width: '100%', height: '480px' }} src={statement.url} />
        </Card>
        </>
      )} */}

      {oral.id !== 0 && (
        <><Divider />
        <Card style={styles.cardItem}>
          {cardTitle('Oral History', oral.mediaType, oral.editHistory)}
          <Row>
            <Col span={6}><Info label={labels.INTERVIEWER} value={oral.interviewer} /></Col>
            <Col span={6}><Info label={labels.LOCATION} value={oral.location} /></Col>
            <Col span={6}><Info label={labels.LENGTH} value={oral.length} /></Col>
            <Col span={6}><Info label={labels.SENT} value={oral.sent.toString()} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Flag label={labels.TRANSCRIBER} value={oral.transcriber} flag={oral.transcribed} note={labels.NOT_TRANSCRIBED} /></Col>
            <Col span={6}><Flag label={labels.TRANSLATOR} value={oral.translator} flag={oral.translated} note={labels.NOT_TRANSLATED} /></Col>
            <Col span={6}><Flag label={labels.EDITOR_BHS} value={oral.editorBHS} flag={oral.editedBHS} note={labels.NOT_EDITED} /></Col>
            <Col span={6}><Flag label={labels.EDITOR_ENG} value={oral.editorEng} flag={oral.editedEng} note={labels.NOT_EDITED} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Info label={labels.SUBTITLES} value={oral.subtitles.toString()} /></Col>
            <Col span={6}><Info label={labels.EDITED_VIDEO} value={oral.editedVideo.toString()} /></Col>
            <Col span={6}><Info label={labels.PAGES} value={oral.pages} /></Col>
            <Col span={6}><Info label={labels.NATIVE_LANGUAGE} value={oral.nativeLanguage} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Row>
            <Col span={6}><Info label={labels.TRANSCRIPT_URL} value={oral.transcriptUrl} /></Col>
            <Col span={6}><Info label={labels.MEDIA_URL} value={oral.mediaUrl} /></Col>
            <Col span={6}><Info label={labels.INTER_DATE} value={moment(oral.interDate).format("DD.MMM.YYYY")} /></Col>
            <Col span={6}><Info label={labels.INTER_NOTES} value={oral.interNotes} /></Col>
          </Row>
          <Divider style={styles.divider} />
          <Info label={labels.TRANS_STORAGE} value={oral.storageTran} />
          <Divider style={styles.divider} />
          <Info label={labels.TEST_STORAGE} value={oral.storageTest} />
          <Divider style={styles.divider} />
          <Info label={labels.NOTES} value={oral.notes} />
        </Card>
        </>
      )}

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowStoryDetailView(false)}
        destroyOnClose={true}
        open={showStoryDetailView}>
        <StoryView id={selectedStoryId} />
      </Drawer>

      <Drawer title={<h2>Object Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowObjectDetailView(false)}
        destroyOnClose={true}
        open={showObjectDetailView}>
        <ObjectView id={selectedObjectId} />
      </Drawer>
    </>
  );
};

export default ParticipantView;


