import TableHead from "services/TableHead";
import service from 'services/apiServices';

const colProps = [
  { key: "participantId", title: "Id", width: '6%', sorter: (a, b) => a.id - b.id, render: x => service.toFormat(x) },
  { key: "participant", title: "Contributor", width: '27%', sorter: (a, b) => a.participant.localeCompare(b.participant) },
  { key: "location", title: "Place of Interview", width: '22%', sorter: (a, b) => a.location.localeCompare(b.location) },
  { key: "mediaType", title: "Media Type", width: '12%', sorter: (a, b) => a.mediaType.localeCompare(b.mediaType) },
  { key: "length", title: "Length", width: '12%' },
  { key: "pages", title: "Pages", width: '5%' }
];

const columns = ({ canEdit, editUrl, canAdd }) => {
  return TableHead( colProps, canEdit, editUrl, canAdd );
}

export default columns;
