import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { Popover, message } from 'antd';
import { Spinner } from 'common';
import config from 'config';
import service from 'services/apiServices';
import Conflict from './conflict';
import mapStyles from './mapStyles';
import { UserOutlined, ReadOutlined, ShoppingOutlined, CustomerServiceOutlined } from '@ant-design/icons';

const iconStyle = {
  color: '#1890ff'
}

const Home = () => {

  const [conflicts, setConflicts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [collection, setCollection] = useState({ name: '', participants: 0, stories: 0, pieces: 0 });

  useEffect(() => {
    async function fetch() {
      try {
        const result = await service.list("conflicts");
        if (result) {
          setCollection(result[result.length - 1]);
          result.splice(result.length - 1, 1);
          setConflicts(result);
          config.conflicts = result;
        }
        setIsLoading(false);
      } catch (error) {
        message.error(config.errorMessage);
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  const WarTitle = ({ name, period }) => {
    return <h3>{name}<br /><small>{period}</small></h3>;
  }

  const Icon = ({ item }) => {
    let period = item.beginYear + '-';
    if (item.endYear !== 0) period += item.endYear;

    return (
      <Popover disabled={true} content={<Conflict id={item.id} data={item} />} title={<WarTitle name={item.name} period={period} />} trigger='click'>
        <PlusCircleTwoTone twoToneColor='red' style={{ fontSize: '2em', cursor: 'pointer' }} title={item.country} />
      </Popover >
    );
  }

  const Mark = ({ text }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 30,
      lng: 50
    },
    zoom: 4
  };

  const OPTIONS = {
    minZoom: 2,
    maxZoom: 8,
  }


  const collstyle = {
    position: 'absolute',
    bottom: 48,
    left: 12,
    border: '2px solid white',
    padding: '8px',
    backgroundColor: 'white',
    color: 'black',
    fontSize: '1.2em'
  }

  if (isLoading) return <Spinner />
  return (
    //  Important! Always set the container height explicitly
    <div style={{ height: '92vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyASeqwIj9e2tPHYxDMHvvpLsaG7zuetCnI' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ styles: mapStyles, ...OPTIONS }}>
        {conflicts.map(c => <Mark key={c.id} lat={c.x} lng={c.y} text={<Icon item={c} />} />)}
      </GoogleMapReact>
      <div style={collstyle}>
        <h3>COLLECTION</h3>
        <hr />
        <div>
          <UserOutlined style={iconStyle} /> {collection.participants} contributors<br />
          <ReadOutlined style={iconStyle} /> {collection.stories} stories<br />
          <ShoppingOutlined style={iconStyle} /> {collection.pieces} objects<br />
          <CustomerServiceOutlined style={iconStyle} /> {collection.orals}
        </div>
      </div>
    </div>
  );
}

export default Home;