import React, { useState, useCallback, useEffect } from "react";
import { message, Layout, Divider, Drawer, Form, Input, Row, Col, Select, DatePicker, Slider, Checkbox, Radio, Space, Modal, Collapse } from "antd";
import FilterNavigator from './FilterNavigator';
import { joinValues, splitValues } from "services/filterServices";
import service from 'services/apiServices';
import { Curtain } from "common";
import FilterList from "./FilterList";
import Objects from "components/objects/objects";
import Participants from "components/participants/participants";
import Stories from "components/stories/stories";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
  select: {
    textAlign: 'left',
    fontSize: '11px',
    borderRadius: '4px',
    border: 'solid 1px black',
    backgroundColor: 'white',
    color: 'black'
  },
  txt: {
    height: '32px'
  },
  col: {
    padding: '0 8px'
  },
  divider: {
    backgroundColor: '#DDC7B0',
    fontSize: '1.2rem',
    marginBottom: 8
  }
};

const AdvancedFilter = ({ filterType, onChangeTitle }) => {

  const [form] = Form.useForm();
  const [conflicts, setConflicts] = useState([]);
  const [cells, setCells] = useState([]);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [mats, setMats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showView, setShowView] = useState(false);
  const [dataSet, setData] = useState([]);

  const master = useCallback(async () => {
    try {
      setLoading(true);
      setConflicts(await service.list('master/conflicts'));
      setCells(await service.list('master/researchCells'));
      setTags(await service.list("master/tags/1"));
      setTypes(await service.list("master/tags/2"));
      setMats(await service.list("master/tags/3"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error);
    }
  }, []);

  useEffect(() => {
    console.log('AdvancedFilter', filterType);
    master();
  }, [master])

  const genderOptions = [
    { label: 'Female', value: '1' },
    { label: 'Male', value: '2' },
    { label: 'Other', value: '3' },
  ]

  const yesNoOptions = [
    { label: 'YES', value: 'YES' },
    { label: 'NO', value: 'NO' }
  ]

  const setDates = range => {
    if (range === null) {
      form.setFieldsValue({
        participationDate1: null,
        participationDate2: null
      })
    } else {
      form.setFieldsValue({
        participationDate1: range[0],
        participationDate2: range[1]
      })
    }
  }

  const setAges = range => {
    form.setFieldsValue({
      age1: range[0],
      age2: range[1]
    });
  }

  const accChanged = range => {
    if (range === null) {
      form.setFieldsValue({
        accessionDate1: null,
        accessionDate2: null
      })
    } else {
      form.setFieldsValue({
        accessionDate1: range[0],
        accessionDate2: range[1]
      })
    }
  }

  const legalOptions = [
    { label: 'Permanent', value: '1' },
    { label: 'Loan', value: '2' },
    { label: 'Loan Ended', value: '3' },
    { label: 'Deaccessioned', value: '4' },
    { label: 'Unknown', value: '5' },
    { label: 'Mixed', value: '6' }
  ]

  const ownerOptions = [
    { label: 'none', value: ' ' },
    { label: 'WCM Bosnia-Herzegovina', value: 'WCM Bosnia-Herzegovina' },
    { label: 'WCM Netherlands', value: 'WCM Netherlands' }
  ]

  const shapeOptions = [
    { label: '2D', value: '2D' },
    { label: '3D', value: '3D' }
  ]

  const statusOptions = [
    { label: 'Documented', value: '1' },
    { label: 'Finalized', value: '2' },
    { label: 'Follow Up', value: '3' }
  ]

  const onFinish = async values => {
    try {
      setLoading(true);
      values = joinValues(values);
      let result;
      if (values.id === 0) {
        values.sqlName = prompt('Please enter a name for this filter');
        if (values.sqlName === null) return;
        result = await service.insert('advancedfilters', values);
      } else {
        result = await service.update('advancedfilters', values, values.id);
      }
      setLoading(false);
      if (result) message.success('The filter parameters successfully saved.');
    } catch (error) {
      setLoading(false);
      message.error('Error occured while saving filter');
    }
  }

  const showSaved = () => setShowList(true);

  const selectFilter = data => {
    data = splitValues(data);
    form.setFieldsValue(data);
    setShowList(false);
    onChangeTitle(data.sqlName);
  };

  const onFailed = error => console.log(error);

  const preview = async data => {
    try {
      setLoading(true);
      const url = 'advancedfilters/runsql/' + filterType;
      if (!data.sqlName) data = joinValues(form.getFieldsValue());
      const result = await service.insert(url, data);
      console.log(result);
      // if (result) {
      //   setData(result);
      //   setShowView(true);
      // } else {
      //   message.error('Error occured while getting sql view');
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Error occured while getting sql view');
    }
  }

  const onReset = () => form.resetFields();

  const takeSnap = async data => {
    try {
      setLoading(true);
      const url = 'advancedfilters/snapshot/' + filterType;
      if (!data.sqlName) data = joinValues(form.getFieldsValue());
      const result = await service.insert(url, data);
      if (result) {
        form.setFieldValue("snapshot", result.snapshot);
      } else {
        message.error('Error occured while taking snapshot');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Error occured while taking snapshot');
    }
  }

  const viewSnap = async id => {
    try {
      setLoading(true);
      if (typeof id === 'object') id = form.getFieldValue('id');
      const result = await service.read('advancedfilters/snapshot', id);
      if (result) {
        setData(result);
        setShowView(true);
      } else {
        message.error('Error occured while getting snapshot');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error('Error occured while getting snapshot');
    }
  }

  if (loading) return <Curtain />;
  return (
    <Layout>
      <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onFailed}>
        <h2>{form.getFieldValue("sqlName")}</h2>
        <FilterNavigator
          showSaved={showSaved}
          submit={() => form.submit()}
          preview={preview}
          onReset={onReset}
          takeSnap={takeSnap}
          haveSnap={form.getFieldValue('snapshot')}
          viewSnap={viewSnap} />

        <Form.Item label='Id' name='id' initialValue={0} hidden><Input /></Form.Item>
        <Form.Item label='Filter Type' name='filterType' initialValue={filterType} hidden><Input /></Form.Item>
        <Form.Item label='Sql name' name='sqlName' initialValue="" hidden><Input /></Form.Item>
        <Form.Item label='Snapshot' name='snapshot' initialValue="" hidden><Input /></Form.Item>

        <Row>
          <Col span={8}>
            <Form.Item label='Armed Conflicts' name='conflicts' initialValue={[]}>
              <Select mode='multiple' style={styles.select} showSearch>
                {conflicts.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Research Cells' name='researchCells' initialValue={[]}>
              <Select mode='multiple' style={styles.select} showSearch>
                {cells.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label='Restrictions on use' name='restrictions' initialValue={[]}>
              <Checkbox.Group options={yesNoOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Collapse defaultActiveKey={filterType}>
          <Panel style={styles.divider} header="Contributors Criteria" key="C">
            <Row>
              <Col span={8}>
                <Form.Item label='Name / Surname' name='name' initialValue=""><Input className='filterField filterInput' /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Birth Place' name='birthPlace' initialValue=""><Input className='filterField filterInput' /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Notes' name='partNotes' initialValue=""><Input className='filterField filterInput' /></Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} offset={1}>
                <Form.Item label='Gender' name="genders" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={genderOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Initial Survey' name="initialSurvey" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Statement' name="statement" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Oral History' name="oralHistory" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Age at the Beginning of the Conflict' name='rangeAge'>
                  <Slider range min={0} max={20} onChange={setAges} />
                </Form.Item>
                <Form.Item label='' name="age1" hidden><Input /></Form.Item>
                <Form.Item label='' name="age2" hidden><Input /></Form.Item>
              </Col>
            </Row>
            <Row>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label='Partner Organization' name="institution" initialValue="">
                  <Input className='filterField filterInput' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Locations During the War' name="locations" initialValue="">
                  <Input className='filterField filterInput' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='First Date of Contribution' name="dateRange">
                  <RangePicker className='filterField filterInput' size='small' onChange={setDates} initialValue={[null, null]} />
                </Form.Item>
                <Form.Item label='' name="participationDate1" hidden><DatePicker /></Form.Item>
                <Form.Item label='' name="participationDate2" hidden><DatePicker /></Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label='Residence at Time of Contribution' name="currentResidence" initialValue="">
                  <Input className='filterField filterInput' />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel style={styles.divider} header="Stories Criteria" key="S">

            <Row>
              <Col span={7} style={styles.col}>
                <Form.Item label='Title / Content' name="content" initialValue=""><Input className='filterField filterInput' /></Form.Item>
              </Col>
              <Col span={7} style={styles.col}>
                <Form.Item label='Location' name="location" initialValue=""><Input className='filterField filterInput' /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Classification' name="class" initialValue={[]}>
                  <Select mode='multiple' optionFilterProp="children" style={styles.select}
                    filterOption={(input, option) => service.anything(input, option.children)}>
                    {tags.map(c => (<Select.Option key={c.id} value={c.name}>{c.name}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label='Operator' name='oper' initialValue="Or" >
                  <Radio.Group buttonStyle='solid' size='small'>
                    <Radio.Button value='And' className='filterField filterRadio'>AND</Radio.Button>
                    <Radio.Button value='Or' className='filterField filterRadio'>OR</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item label='Has objects' name='objects' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label='Story Status' name="status" initialValue={[]}>
                  <Checkbox.Group>
                    <Space direction='vertical'>
                      {statusOptions.map(o => <div key={o.value}><Checkbox value={o.value}>{o.label}</Checkbox></div>)}
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label='Memory of an object' name='imaginary' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label='Notes' name="storyNotes" initialValue="">
                  <Input className='filterField filterInput' style={styles.select} />
                </Form.Item>
                <Form.Item label='Additional information source' name="additional" initialValue="">
                  <Input className='filterField filterInput' style={styles.select} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel style={styles.divider} header="Objects Criteria" key="O">

            <Row>
              <Col span={8}>
                <Form.Item label='Object Name / Description' name='description' initialValue="">
                  <Input className='filterField filterInput' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Storage location' name="storageLocation" initialValue="">
                  <Input className='filterField filterInput' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Owned by' name="ownedBy" style={{ textAlign: 'left' }} initialValue="">
                  <Select optionFilterProp="children" style={styles.select}
                    filterOption={(input, option) => service.anything(input, option.children)}>
                    {ownerOptions.map(c => (<Select.Option key={c.label} value={c.value}>{c.value}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label='Object Type' name="objectType" initialValue={[]}>
                  <Select mode='multiple' style={styles.select} showSearch>
                    {types.map(c => (<Select.Option key={c.id} value={c.name}>{c.name}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col><Col span={8}>
                <Form.Item label='Material' name="material" initialValue={[]}>
                  <Select mode='multiple' style={styles.select} showSearch>
                    {mats.map(c => (<Select.Option key={c.id} value={c.name}>{c.name}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='' name="accessionDate1" hidden><DatePicker /></Form.Item>
                <Form.Item label='' name="accessionDate2" hidden><DatePicker /></Form.Item>
                <Form.Item label='Accession Date' name="accRange">
                  <RangePicker className='filterField filterInput' size='small' onChange={accChanged} initialValue={[null, null]} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item label='Study Collection' name="study" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Digital' name="digital" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Copy' name="copy" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Has Notes' name="hasNotes" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Handmade' name="handmade" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Broken Pieces' name="broken" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item label='Handling Requirements' name="handling" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Conservation' name="conservation" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Has Images' name="hasImage" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Has Parts' name="hasParts" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Condition Report' name="conditionReport" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Shape' name="shape" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={shapeOptions} />
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col span={4} offset={4}>
                <Form.Item label='Current Legal Status' name="legalStatus" style={{ textAlign: 'left' }} initialValue={[]}>
                  <Checkbox.Group options={legalOptions} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Loan Status' name="loanType" style={{ textAlign: 'left' }} initialValue="0">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="1">Incoming</Radio>
                      <Radio value="2">Outgoing</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Loan Date" name="loanDate"><DatePicker initialValue={null} /></Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Loan Information' name="loanStatus" style={{ textAlign: 'left' }} initialValue="0">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="1">On Loan</Radio>
                      <Radio value="2">Past Loan</Radio>
                      <Radio value="3">Never</Radio>
                      <Radio value="4">Not Currently</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <hr />
          </Panel>
        </Collapse>
      </Form>
      <Drawer
        title={<h2>Advanced Filters</h2>}
        width={'60%'}
        placement="right"
        onClose={() => setShowList(false)}
        destroyOnClose={true}
        open={showList}>
        <FilterList
          filterType={filterType}
          selectFilter={selectFilter}
          preview={preview}
          takeSnap={takeSnap}
          viewSnap={viewSnap} />
      </Drawer>

      <Modal width={'90%'} open={showView} footer={[]} onCancel={() => setShowView(false)} style={{ top: 20 }} destroyOnClose={true}>
        {filterType === 'C' && <Participants dataSource={dataSet} />}
        {filterType === 'S' && <Stories dataSource={dataSet} />}
        {filterType === 'O' && <Objects dataSource={dataSet} />}
      </Modal>

    </Layout >
  );
}

export default AdvancedFilter;