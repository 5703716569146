import Cookies from 'universal-cookie';
import config from 'config';

const cookies = new Cookies();

const isSuper = () => config.currentUser.role === 'SuperAdmin';

const isAdmin = () => config.currentUser.role.includes('Admin');

const notIntern = () => 'SuperAdmin,Admin,User'.includes(config.currentUser.role);

const isLogged = () => config.currentUser.id !== 0;

const setToken = token => {
  clearToken();
  let expires = new Date();
  expires = new Date(expires.getTime() + 1000 * 60 * 60 * 8); // 8 hours
  // expires = new Date(expires.getTime() + 1000 * 60); // 1 minute
  cookies.set('wcmToken', token, { expires: expires });
}

const getToken = () => cookies.get('wcmToken');

const clearToken = () => cookies.remove('wcmToken');

export { isLogged, notIntern, isAdmin, isSuper, getToken, setToken, clearToken }
