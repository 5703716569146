import React, { useState, useEffect, useCallback } from "react";
import { Table, Layout, Drawer, message } from "antd";
import _ from 'lodash';

import service from "services/apiServices";
import { Filter } from "common";
import { isAdmin } from "services/authServices";
import config from "config";
import columns from "./columns";
import OralHistoryView from "./oralHistoryView";

const { Content } = Layout;

const OralHistories = () => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [currentPage, setCurrentPage] = useState(config.headers.page);

  const onChange = o => {
    config.headers.page = o.current;
    setCurrentPage(config.headers.page);
  };

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list('oralhistory');
      if (result) setData(result);
      setCurrentPage(config.headers.page);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refreshPage = () => fetch();

  const TableTitle = () => {
    return (
      <div>
        <div style={{ float: 'left' }}><h2 align='left'>Oral Histories / {data.length}</h2></div>
        <Filter onChange={refreshPage} />
      </div>
    )
  }

  return (
    <Layout className="container">
      <Content>
        <Table
          loading={isLoading}
          title={TableTitle}
          size="small"
          tableLayout={"fixed"}
          columns={columns({
            canEdit: isAdmin(),
            editUrl: 'oral',
            canAdd: isAdmin()
          })}
          dataSource={data}
          onChange={onChange}
          onRow={(record) => ({
            onClick: () => {
              const partId = _.find(data, { id: record.id }).participantId;
              setSelectedId(partId);
              setShowDetail(true);
              }
          })}
          pagination={{ current: currentPage, size: 'small', hideOnSinglePage: true, showSizeChanger: true, showQuickJumper: true }}
          rowKey={(row) => row.id}
        />
      </Content>

      <Drawer
        title={<h2>Oral History Detail View</h2>}
        width={'60%'}
        placement="right"
        onClose={() => setShowDetail(false)}
        open={showDetail}>
        <OralHistoryView id={selectedId} />
      </Drawer>
    </Layout>
  );
};

export default OralHistories;
