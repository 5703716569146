import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Select, Layout, Button, Radio, Row, Col, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';

import service from 'services/apiServices';
import config from 'config';
import { Buttons, Curtain, Delete } from 'common';

const { Content } = Layout;
const { Dragger } = Upload;

const styles = {
  userField: {
    textAlign: 'left',
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#fafaff',
    color: 'black'
  }
}

const Translation = ({ item, langs, onOk }) => {

  const editor = useRef(null);
  const [media, setMedia] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [text, setText] = useState('');

  useEffect(() => {
    form.setFieldsValue({ ...item })
    setText(item.text);
    if (item.audio !== 'n/a') setMedia(config.mediaPath + item.audio);
  }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    let result = false;
    setLoading(true);
    if (item.id === 0) {
      result = await service.insert('storyTranslations', { ...values, story: { id: service.toNumber(item.storyId) } });
    } else {
      result = await service.update('storyTranslations', { ...values, story: { id: service.toNumber(item.storyId) } }, item.id);
    }
    setLoading(false);
    if (result) {
      onOk();
    }
  };

  const options = {
    name: 'file',
    multiple: true,
    action: `${config.uploadPath}media/T${item.storyId}/${item.language}`,
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onSuccess(body) {
      form.setFieldsValue({ audio: body });
      setMedia(config.mediaPath + body);
      setLoading(false);
    },
    async onChange(info) {
      setLoading(true);
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        setLoading(false);
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
        setLoading(false);
      }
    }
  }

  const remove = () => {
    form.setFieldValue('audio', 'n/a');
    setMedia('');
  }

  return (
    <Layout>
      {loading && <Curtain />}
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
          <Row>
            <Col span={24}>
            <Form.Item label='Title' name='title'><Input style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label='Language' name='language'>
                <Select className="stepFormField" mode="combobox" style={styles.userField}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}>
                  {langs.map(p => (<Select.Option key={p.id} value={p.name}>{p.name}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label="Status" name="status" style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='Created'>Created</Radio.Button>
                  <Radio.Button value='Proofread'>Proofread</Radio.Button>
                  <Radio.Button value='Finalized'>Finalized</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8} align='right'>
              <Form.Item label='audio' name='audio' hidden><Input /></Form.Item>
              {!!media && <audio controls controlsList='nodownload' className='audio'><source src={media} type="audio/mpeg" />Your browser does not support the audio element.</audio>}
              <Dragger {...options} className='media-upload'>
                <div className='ant-upload-text' style={{ marginTop: '-48px' }}><InboxOutlined style={{ fontSize: '2em' }} /> UPLOAD MEDIA</div>
              </Dragger>
              {!!media && <Button type='primary' onClick={remove} style={{ marginTop: '6px', width: '100%' }}>Remove audio file</Button>}
            </Col>
          </Row>
          <Form.Item label='Text' name='text' hidden><Input /></Form.Item>
          <Form.Item label='Story' name='storyId' hidden><Input /></Form.Item>
          <Row>
            <Col align='left'>
              <JoditEditor
                ref={editor}
                value={text}
                config={{
                  readonly: false,
                  showCharsCounter: false,
                  showWordsCounter: false,
                  showXPathInStatusbar: false,
                  buttons: [
                    'bold', 'strikethrough', 'underline', 'italic', '|',
                    'spellcheck', 'cut', 'copy', 'paste', 'selectAll', '  ',
                    'undo', 'redo', 'find', 'fullsize', 'preview', 'print'
                  ],
                  toolbarButtonSize: 'large',
                  useSplitMode: true
                }}
                onBlur={newContent => {
                  newContent = newContent.trim().replaceAll('<br>', '</p><p>');
                  newContent = newContent.trim().replaceAll('<p></p>', '');
                  setText(newContent);
                  form.setFieldsValue({ text: newContent })
                }
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={9} offset={4} style={{ textAlign: 'left' }}><Delete dataSet='storyTranslations' id={item.id} onFinish={onOk} /></Col>
            <Col span={11} style={{ textAlign: 'right' }}><Button style={Buttons.button} htmlType='submit'>SAVE</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Translation;

// {media !== 'n/a' && <PlayCircleTwoTone style={{ fontSize: '3em', paddingTop: '64px', cursor: 'pointer' }} onClick={listen} title='listen the media' />}

// className='media-upload' >