import React, { useCallback, useState, useEffect, useRef } from "react";
import { Form, Button, Input, Modal, Switch, Radio, Layout, Row, Col, message, Select, Upload, Divider } from "antd";
import { useParams } from "react-router-dom";
import { InboxOutlined } from '@ant-design/icons';
import JoditEditor from "jodit-react";
import _ from 'lodash';

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import { EditHistory, Curtain, Delete, Title, Buttons } from "common";
import Objects from './components/objects';

import { Notes, Translations, Attachments } from './components'
import config from "config";

const { Dragger } = Upload;

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
const swiout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
const imgStyle = {
  width: '80%',
  border: '1px solid black'
}

const StoryEdit = ({ history }) => {

  if (!isAdmin()) history.push('/stories');

  const editor = useRef(null);
  const imagePath = 'story?id=' + useParams().id;
  const [storyId, setStoryId] = useState(useParams().id);
  const [lastEdit, setLastEdit] = useState({});
  const [photo, setPhoto] = useState('');
  const [media, setMedia] = useState('');
  const [final, setFinal] = useState('');
  const [work, setWork] = useState('');
  const [locked, setLocked] = useState(false);

  const isNew = useParams().id === '0';

  const [pid, setPid] = useState('');
  const [title, setTitle] = useState('');

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [tags, setTags] = useState([]);
  const [lang, setLang] = useState([]);

  const [visWork, setVisWork] = useState(false);
  const [visText, setVisText] = useState(false);
  const [visNotes, setVisNotes] = useState(false);
  const [visTrans, setVisTrans] = useState(false);
  const [visAttach, setVisAttach] = useState(false);
  const [visObject, setVisObject] = useState(false);

  const showWork = () => {
    setWork(form.getFieldValue('workVersion'));
    setVisWork(true);
  }
  const hideWork = () => setVisWork(false);
  const showText = () => {
    setFinal(form.getFieldValue('text'));
    setVisText(true);
  }
  const hideText = () => setVisText(false);
  const showNotes = () => setVisNotes(true);
  const hideNotes = () => setVisNotes(false);
  const showTrans = () => setVisTrans(true);
  const hideTrans = () => setVisTrans(false);
  const showAttach = () => setVisAttach(true);
  const hideAttach = () => setVisAttach(false)

  const getPeople = useCallback(async () => {
    try {
      const result = await service.list("master/participants");
      setPeople(result);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, []);

  const getTags = useCallback(async () => {
    try {
      let result = await service.list("master/tags/1");
      setTags(result);
      result = await service.list("master/tags/5");
      setLang(result);
    } catch (error) {
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, []);

  const getStory = useCallback(async (storyId) => {
    try {
      const result = await service.read("stories", storyId + "/edit");
      form.setFieldsValue({ ...result, storyId: result.id, pid: result.id });
      setLastEdit({ lastUpdate: result.lastUpdate, editHistory: result.editHistory });
      setPhoto(result.photo);
      setPid(result.id);
      setTitle(result.title);
      setLocked(result.status === 'Finalized');
      if (result.audio !== 'n/a') setMedia(config.mediaPath + result.audio);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    if (parseInt(storyId) && !isNew) getStory(storyId);
    getPeople();
    getTags();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const modalTitle = (subTitle, storyId, title) => {
    let res = subTitle + " ( ";
    if (storyId) res += storyId + " ";
    if (title) res += title;
    res += ")";
    return <h3>{res}</h3>
  }

  const onFailed = () => {
    message.error("We are experienciing some network related issues. Please try again in a minute.");
  };

  const onFinish = async (values) => {
    const data = { ...values, id: service.toFormat(storyId), pid: service.toFormat(storyId) };
    if (!values.workVersion) values.workVersion = "<p></p>";
    if (!values.text) values.text = "<p></p>";
    let result = false;
    setLoading(true);
    if (storyId === '0') {
      result = await service.insert('stories', data);
      if (result) {
        message.success("Story successfully inserted in the database.");
        config.stories.push(result);
        config.stories = _.orderBy(config.stories, 'id', 'asc');
        setStoryId(result.id);
        form.setFieldsValue({ ...result });
        let i = _.findIndex(config.stories, { id: result.id });
        config.headers.page = Math.ceil((i + 1) / 10);
        setLoading(false);
        history.push('/stories');
      }
    } else {
      result = await service.update('stories', data, storyId);
      if (result) {
        message.success("Story successfully updated in the database.");
        let index = _.findIndex(config.stories, _.pick(result, 'id'));
        if (index !== -1) config.stories.splice(index, 1, result);
        let i = _.findIndex(config.stories, { id: result.id });
        config.headers.page = Math.ceil((i + 1) / 10);
        setLoading(false);
        history.push('/stories');
      }
    }
  };

  const onDelete = () => {
    setLoading(true);
    _.remove(config.stories, item => item.id === storyId);
    history.push('/stories');
    setLoading(false);
  }

  const options = {
    name: 'file',
    multiple: false,
    action: config.uploadPath + imagePath,
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onSuccess(body) {
      form.setFieldsValue({ photo: body });
      setPhoto(body);
      setLoading(false);
    },
    onChange(info) {
      setLoading(true);
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        setLoading(false);
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        setLoading(false);
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const medopt = {
    name: 'file',
    multiple: true,
    action: `${config.uploadPath}media/S${storyId}/_`,
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onSuccess(body) {
      form.setFieldsValue({ audio: body });
      setMedia(config.mediaPath + body);
      setLoading(false);
    },
    async onChange(info) {
      setLoading(true);
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        setLoading(false);
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
        setLoading(false);
      }
    }
  }

  const wordCount = (label, field, func) => {
    let wc = 0;
    let x = form.getFieldValue(field);
    if (x) {
      x = x.replaceAll("<p>", "").replaceAll("</p>", "");
      if (x) wc = x.split(' ').length;
    }
    return (
      <>
        <div className='left' style={{ fontSize: '1.2em', fontWeight: '600' }}>{label}{!!wc && <span>: [{wc} words]</span>}</div>
        {!locked && <div className='right'><Button type='primary' size='small' onClick={func}>EDIT</Button></div>}
        {locked && <div className='right'><Button type='primary' size='small' style={{ color: 'white', backgroundColor: 'red' }}>LOCKED</Button></div>}
      </>
    );
  }

  const participantSelected = async value => {
    if (storyId === '0') {
      let next = await service.read('stories/next', value);
      if (next) {
        next = service.toFormat(next);
        setStoryId(next);
        setTitle(form.getFieldValue("title"));
        form.setFieldValue("storyId", next);
        setPid(next);

        const data = {
          id: next,
          pid: next,
          title: form.getFieldValue("title"),
          participantId: value
        };
        const result = await service.insert('stories', data);
        if (result) {
          config.stories.push(result);
          config.stories = _.orderBy(config.stories, 'id', 'asc');
          setStoryId(result.id);
          form.setFieldsValue({ ...result });
        }
      }
    }
  };

  const nameOpt = (id, name) => name + ' (' + service.toFormat(id) + ')';

  const remove = () => {
    form.setFieldValue('audio', 'n/a');
    setMedia('');
  }

  const formatEdit = () => {
    return config.currentUser.name === "Fernanda Flores" || config.currentUser.name === "Sulejman Ćatibušić" ?
      [<Button type='primary'>FORMAT</Button>] : [null];
  }

  return (
    <div>
      {loading && <Curtain />}
      <Layout className="container">
        {/* <Prompt when={formDirty} message={'Are you sure you wish to leave this page\r\nwithout saving the changes?'} /> */}
        <Title align="center">Story Edit {!!pid && <span>{pid}: {title}</span>}</Title>
        <EditHistory lastUpdate={lastEdit.lastUpdate} editHistory={lastEdit.editHistory} />
        <div className='divider' />
        <Form form={form} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label="Id" name="storyId" hidden><Input className="stepFormField" /></Form.Item>
          <Form.Item label="Pid" name="pid" hidden><Input className="stepFormField" /></Form.Item>
          <Row>
            <Col span={9}>
              <Form.Item label="Title" name="title"><Input className="stepFormField" /></Form.Item>
              <Form.Item label="Contributor" name="participantId">
                <Select className="stepFormField" mode="combobox"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  onSelect={participantSelected}
                  style={{ textAlign: 'left' }}>
                  {people.map(p => (<Select.Option key={p.id} value={service.toFormat(p.id)}>{nameOpt(p.id, p.name)}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="Native Language" name="nativeLanguage">
                <Select className="stepFormField" mode="combobox"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {lang.map(p => (<Select.Option key={p.id} value={p.name}>{p.name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="Location" name="location"><Input className="stepFormField" /></Form.Item>
              <Form.Item label='Restrictions on use' name='restrictions' valuePropName='checked' style={{ textAlign: 'left' }} {...swiout}>
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
              </Form.Item>
              <Form.Item label="Status" name="status" style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value="Documented">Documented</Radio.Button>
                  <Radio.Button value="Finalized">Finalized</Radio.Button>
                  <Radio.Button value="FollowUp">Follow Up</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Classification 1" name="class1" initialValue="">
                <Select className="stepFormField" mode="combobox"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {tags.map(t => (<Select.Option key={t.id} value={t.name}>{t.name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="2" name="class2" initialValue="">
                <Select className="stepFormField" mode="combobox"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {tags.map(t => (<Select.Option key={t.id} value={t.name}>{t.name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="3" name="class3" initialValue="">
                <Select className="stepFormField" mode="combobox"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {tags.map(t => (<Select.Option key={t.id} value={t.name}>{t.name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item label="Additional source" name="additional" initialValue=""><Input className="stepFormField" /></Form.Item>
            </Col>
            <Col span={9} offset={1} align='left'>
              {wordCount('Final version', 'text', showText)}
              <div className='story-frame' dangerouslySetInnerHTML={{ __html: form.getFieldValue('text') }} />
              <Divider />
              {wordCount('Working Version', 'workVersion', showWork)}
              <div className='story-frame' dangerouslySetInnerHTML={{ __html: form.getFieldValue('workVersion') }} />
              <Form.Item label="" name="text" hidden><Input /></Form.Item>
              <Form.Item label="" name="workVersion" hidden><Input /></Form.Item>
            </Col>
            <Col span={5} align='right'>

              <div className="imaginary">
                <div style={{ flex: 2 }}>
                  Memory of an object:
                </div>
                <div style={{ flex: 1, marginTop: '24px' }}>
                  <Form.Item label='' name='imaginary' valuePropName='checked' style={{ textAlign: 'right', marginLeft: '12px' }}>
                    <Switch checkedChildren="YES" unCheckedChildren="NO" />
                  </Form.Item>
                </div>
              </div>
              <div className="divider" />

              <Form.Item label="Photo" name="photo" hidden><Input className="stepFormField" /></Form.Item>
              <img src={`${config.imagePath}/${photo}`} alt={photo} style={imgStyle} />
              <p>&nbsp;</p>
              <Dragger {...options} className='media-upload'>
                <div style={{ marginTop: '-42px' }}><InboxOutlined style={{ fontSize: '2em' }} /> UPLOAD IMAGE</div>
              </Dragger>
              <p>&nbsp;</p>
              <Form.Item label='audio' name='audio' hidden initialValue="n/a"><Input /></Form.Item>
              {!!media &&
                <audio controls controlsList='nodownload' className='audio'>
                  <source src={media} type="audio/mpeg" />Your browser does not support the audio element.
                </audio>
              }
              <Dragger {...medopt} className='media-upload'>
                <div style={{ marginTop: '-42px' }}><InboxOutlined style={{ fontSize: '2em' }} /> UPLOAD MEDIA</div>
              </Dragger>
              {!!media && <Button type='primary' onClick={remove} style={{ marginTop: '6px', width: '100%' }}>Remove audio file</Button>}
            </Col>
          </Row>
          <div className='divider' />
          {config.currentUser.delStories && <Delete dataSet='stories' id={storyId} onFinish={onDelete} />}
          <Button style={Buttons.close} onClick={() => history.push('/stories')}>Close</Button>
          <Button style={Buttons.button} onClick={() => setVisObject(true)}>Objects</Button>
          <Button style={Buttons.button} onClick={showTrans}>Translations</Button>
          <Button style={Buttons.button} onClick={showAttach}>Attachments</Button>
          <Button style={Buttons.button} onClick={showNotes}>Notes</Button>
          <Button style={Buttons.confirm} htmlType='submit'>SAVE</Button>

          <Modal width={'80%'}
            open={visText}
            title={modalTitle('Final version')}
            footer={formatEdit()}
            onCancel={hideText}
            style={{ top: 20 }}>
            <JoditEditor
              ref={editor}
              value={final}
              config={{
                readonly: false,
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
                buttons: [
                  'bold', 'strikethrough', 'underline', 'italic', '|',
                  'spellcheck', 'cut', 'copy', 'paste', 'selectAll', '  ',
                  'undo', 'redo', 'find', 'fullsize', 'preview', 'print'
                ],
                toolbarButtonSize: 'large',
                useSplitMode: true
              }}
              onBlur={newContent => {
                newContent = newContent.trim().replaceAll('<br>', '</p><p>');
                newContent = newContent.trim().replaceAll('<p></p>', '');
                setFinal(newContent);
                form.setFieldsValue({ text: newContent })
              }}
            />
          </Modal>

          <Modal width={'80%'}
            open={visWork}
            title={modalTitle('Work Version')}
            footer={formatEdit()}
            onCancel={hideWork}
            style={{ top: 20 }}>
            <JoditEditor
              ref={editor}
              value={work}
              config={{
                readonly: false,
                showCharsCounter: false,
                showWordsCounter: false,
                showXPathInStatusbar: false,
                buttons: [
                  'bold', 'strikethrough', 'underline', 'italic', '|',
                  'spellcheck', 'cut', 'copy', 'paste', 'selectAll', '  ',
                  'undo', 'redo', 'find', 'fullsize', 'preview', 'print'
                ],
                toolbarButtonSize: 'large',
                useSplitMode: true
              }}
              onBlur={newContent => {
                newContent = newContent.trim().replaceAll('<br>', '</p><p>');
                newContent = newContent.trim().replaceAll('<p></p>', '');
                setWork(newContent);
                form.setFieldsValue({ workVersion: newContent })
              }}
            />
          </Modal>
        </Form>
        <Modal width={'70%'} open={visNotes} title={modalTitle('NOTES')} footer={[]} onCancel={hideNotes}><Notes story={storyId} /></Modal>
        <Modal width={'70%'} open={visTrans} title={modalTitle('TRANSLATIONS')} footer={[]} onCancel={hideTrans}><Translations story={storyId} langs={lang} /></Modal>
        <Modal width={'70%'} open={visAttach} title={modalTitle('ATTACHMENTS')} footer={[]} onCancel={hideAttach}><Attachments story={storyId} /></Modal>
        <Modal width={'90%'} open={visObject} title={modalTitle('OBJECTS', pid, title)} footer={[]} onCancel={() => setVisObject(false)} style={{ top: 20 }}>
          <Objects story={storyId} owner={form.getFieldValue("participantId")} />
        </Modal>
      </Layout>
    </div>
  );
};

export default StoryEdit;
